@colorMain: #894D9A;
@colorActive: #FB607F;
@colorText: #000000;
@colorLinkHover: #894D9A;

html, body {
	min-height: 100%;
}

body {
	background: white;
	margin: 0;
	font-family: 'Commissioner', sans-serif;
	font-size: 16px;
	color: @colorText;
}

.fontJost {
	font-family: 'Jost', sans-serif;
}

.fontBlack {
	font-family: 'Jost', sans-serif;
	font-weight: 900;
}

a {
	color: @colorText;

	&:hover {
		color: @colorLinkHover;
	}
}

// Чтобы в firefox вокруг ссылок не было точек при нажатии
:focus {outline:none;}
::-moz-focus-inner {border:0;}


.header--ads {
	margin-top: 50px;
	margin-bottom: 50px;
}

.ads-content-yandex {
	margin-top: 40px;
	margin-bottom: 60px;
}

.ads-article-bottom {
	margin-top: 40px;
}

.header--ads,
.ads-content-yandex,
.ads-article-bottom {
	img {
		max-width: 100%;
		display: block;
	}
}

// Шарина контента
.container-width {
	max-width: 1290px;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
}

.grid,
.items {
	display: grid;
}

.flex {
	display: flex;
}


header {
	margin-top: 7px;
	margin-bottom: 7px;
	color: white;
	background-color: @colorMain;
	position: relative;
	z-index: 2;

	.flex {
		justify-content: space-between;
		height: 75px;
	}
}

.logo {
	margin-top: 4px;
	margin-right: 25px;

	img {
		display: block;
	}
}

.main-nav {
	display: flex;
	align-items: stretch;
	margin-right: 30px;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: stretch;

		ul {
			display: none;
			position: absolute;
			background: @colorActive;
			padding: 6px 20px 11px;
			margin-top: 75px;
			z-index: 2;

			li {
				margin-top: -3px;
			}

			a {
				padding: 7px 0 5px;
				color: white;
				text-decoration: none;
				position: relative;
				transition-duration: .2s;

				background-image: linear-gradient(to right, @colorMain 75%, #0000 75%);
				background-position: 0 1.9em;
				background-repeat: repeat-x;
				background-size: 1px 0;

				&:hover {
					background-size: 1px 0.14em;
				}

				// &:after {
				// 	content: '';
				// 	display: block;
				// 	height: 0;
				// 	background: @colorMain;
				// 	width: 100%;
				// 	position: absolute;
				// 	bottom: 8px;
				// 	transition-duration: .15s;
				// }

				// &:hover {
				// 	&:after {
				// 		height: 2px;
				// 	}
				// }
			}
		}
	}

	li {
		display: flex;
		align-items: stretch;
	}

	a, span {
		color: white;
		.fontJost;
		font-size: 18px;
		text-decoration: none;
		transition-duration: .2s;
		cursor: pointer;
	}

	>ul>li {
		>a, >span {
			padding: 14px;
			display: block;
			display: flex;
			align-items: center;
		}
		

		&:hover,
		&.active {
			>a, >span {
				margin-bottom: -10px;
				padding-bottom: 24px;
				background: @colorActive;
			}
		}
	}

	&:hover {
		>ul>li {
			&.active {
				>a {
					margin-bottom: 0;
					padding-bottom: 14px;
					background: none;
				}
			}

			&:hover {
				>a {
						margin-bottom: -10px;
						padding-bottom: 24px;
						background: @colorActive;
				}
			}
		}
	}
}

.header-sorting {
	margin: -7px 0;
	border: 2px solid @colorActive;
	background: @colorMain;
	color: white;
	display: flex;
	align-items: stretch;
	font-size: 18px;
	position: relative;
	transition-duration: .2s;
	opacity: 1;

	a {
		color: white;
		text-decoration: none;
	}
}

.header-sorting--select {
	position: absolute;
	left: 0;
	top: 0;
	border: 2px solid @colorActive;
	background: @colorMain;
	margin: -2px;
	display: none;
}

.header-sorting--option {
	display: flex;
	align-items: center;
	padding: 27px;
	padding-left: 15px;
	padding-right: 27px;
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	line-height: 31px;
}

.header-sorting--current {
	&:after {
		@size: 7px;
		content: '';
		display: block;
		border: @size solid transparent;
		border-top: @size solid white;
		margin-bottom: -@size;
		margin-left: 8px;
	}
}

.header-sorting--icon-popular,
.header-sorting--icon-fresh {
	&:before {
		content: '';
		display: inline-block;
		background-position: center;
		background-repeat: no-repeat;
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
}

.header-sorting--icon-popular {
	&:before {
		background-image: data-uri('../img/popular.svg');
	}
}

.header-sorting--icon-fresh {
	&:before {
		background-image: data-uri('../img/fresh.svg');
	}
}


.header-feedback {
	border: none;
	border-radius: 0;
	background: @colorActive;
	color: white;
	cursor: pointer;
	margin-left: 20px;
	padding: 10px;
	width: 204px;
	font-size: 18px;
	.fontJost;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	transition-duration: .2s;
	opacity: 1;
	text-align: center;

	&:hover {
		margin-bottom: -7px;
		padding-bottom: 7px;
		color: white;
	}
}


.header-search {
	display: flex;
	margin-left: 0;
	align-items: stretch;
	position: relative;

	button {
		border: none;
		cursor: pointer;
		background: none;
		background-repeat: no-repeat;
		background-position: center;
	}

	.button-open {
		width: 50px;
	}

	.button-open,
	.button-search {
		background-image: data-uri('../img/search.svg');
	}

	.button-close {
		background-image: data-uri('../img/close-white.svg');
	}

	input[type="search"] {
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			-webkit-appearance: none;
			display: none; 
		}

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0; 
		}
	}

	form {
		display: grid;
		grid-template-columns: 1fr 44px 44px;
		// opacity: 0;
		width: 0;
		position: absolute;
		top: 0;
		right: 0;
		background: @colorActive;
		color: white;
		height: ~"calc(100% - 6px)";
		margin-top: 3px;
		overflow: hidden;
		transition-duration: .2s;
	}

	input {
		border: none;
		color: white;
		background: none;
		padding: 0 27px;
		font-size: 18px;
		.fontJost;

		&::placeholder {
			color: white;
			font-size: 18px;
			.fontJost;
			font-style: italic;
		}
	}
}

.header-search-opened {
	.header-sorting,
	.header-feedback {
		opacity: 0;
	}

	.header-search {
		form {
			width: 516px;
		}
	}
}

h1, .h1,
h2, .h2,
h3, .h3 {
	.fontJost;
}

.h1-box {
	margin-top: 1.75em;
	margin-bottom: 2em;
}

h1, .h1 {
	font-weight: 900;
	font-size: 86px;
	line-height: 107px;

	display: inline;
	background-image: linear-gradient(to right, @colorActive 75%, #0000 75%);
	background-repeat: repeat-x;
	background-size: 1px 0.25em;
	background-position: 0 0.98em;
}

.purple-block {
	background: @colorMain;
	box-shadow: 20px 20px 0 black;
	color: white;
}

.front--about {
	box-shadow: 30px 30px 0 black;
	padding: 5px 28px 15px;
	margin-right: 82px;
	font-size: 19px;
	line-height: 1.45em;
	margin-bottom: 109px;
}


.items {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 61px 30px;
}

.items-3 {
	grid-template-columns: 1fr 1fr 1fr;
}

.item {
	p {
		margin-top: 5px;
    margin-bottom: 16px;
    line-height: 22px;
	}
}

.item--img {
	position: relative;
	display: block;
	color: black;
	width: fit-content;
	width: -moz-fit-content;

	&:hover {
		color: black;
	}

	img {
		max-width: 100%;
		border: 1px solid @colorMain;
	}
}

.item--stat {
	position: absolute;
	right: 3px;
	bottom: 5px;
	background: white;
	display: flex;
	font-size: 12px;
	line-height: 15px;
	padding: 5px 8px;

	>*:not(:first-child) {
		margin-left: 8px;
	}
}

.item--views,
.item--comments {
	display: flex;

	&:before {
		content: '';
		display: block;
		width: 12px;
		height: 14px;
		margin-right: 5px;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.item--views {
	&:before {
		background-image: data-uri('../img/eye.svg');
	}
}

.item--comments {
	&:before {
		background-image: data-uri('../img/comment.svg');
	}
}

.item--name {
	.fontJost;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
	color: @colorText;
	text-decoration: none;
}

.tags {
	a {
		display: inline-block;
		padding: 2px 5px;
		background: @colorActive;
		color: white;
		text-decoration: none;
		margin-bottom: 5px;
		margin-right: 4px;
		font-size: 15px;
		line-height: 22px;
	}
}

.item--big {
	grid-column-end: span 3;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	margin-top: -2px;

	.item--img {
		margin-bottom: 6px;
	}

	img {
		border: none;
	}

	p {
		margin-top: 5px;
		margin-bottom: 19px;
	}
}

.item--w2 {
	grid-column-end: span 2;
}

.big-icon {
	display: grid;
	grid-template-columns: 1.2fr 1fr;
	color: white;
	align-items: stretch;
	margin-bottom: 8px;

	h2, .h2 {
		.fontJost;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 30px;
		margin-top: 0;
		margin-bottom: 20px;
	}

	p {
		.fontJost;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 30px;
		margin-top: 29px;
	}
}

.big-icon--content {
	align-items: stretch;
	padding: 48px 21px 35px;
}

.big-icon--icon {
	margin-top: -40px;
	margin-bottom: -3px;
}

.big-icon--books {
	background: @colorMain;
}

.big-icon--success {
	background: @colorActive;

	img {
		position: relative;
    top: -11px;
    left: 27px;
	}
}

.item--w-full {
	grid-column-start: 1;
	grid-column-end: -1;
}

.questions {
	background: @colorActive;
	color: white;
	display: grid;
	grid-template-columns: 1fr 2.13fr;
	grid-gap: 30px;
	.fontJost;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
	padding: 20px;
	border: 1px solid @colorMain;
	margin-top: 12px;
	align-items: center;
	margin-bottom: 8px;
}

.questions--email {
	font-size: 86px;
	line-height: 50px;
	color: white;
	text-decoration: none;
	background-image: linear-gradient(to right, @colorMain 75%, #0000 75%);
	background-repeat: repeat-x;
	background-size: 1px 0.25em;
	background-position: 0 0.98em;

	&:hover {
		color: white;
	}
}

.more-articles {
	text-align: center;
	margin-top: 79px;
}


.darken-block {
	background-color: @colorLinkHover;
	color: white;

	a {
		color: white;
	}
}

.button,
.darken-block .button,
main button {
	text-decoration: none;
	color: @colorMain;
	background: white;
	font-family: Jost;
	font-weight: bold;
	font-size: 24px;
	line-height: 22px;
	border: 1px solid @colorMain;
	display: inline-block;
	padding: 26px 41px;
	cursor: pointer;
	transition-duration: .1s;
	
	&:hover,
	&:active {
		color: white;
		background: @colorMain;
		border-color: white;
	}
}


main {
	margin-bottom: 41px;
}


footer {
	background-image: data-uri('../img/footer.svg');
	background-repeat: no-repeat;
	background-position: center;
	// background-size: contain;
	
	.container-width {
		padding-top: 31px;
		display: grid;
		grid-template-columns: 1.4fr 1fr 1fr 1fr 1fr;
		grid-template-areas: 
		'logo business business menu menu'
		'emailus business business menu menu'
		'hr hr hr hr hr'
		'copyprivacy copyprivacy copyprivacy copyprivacy copyprivacy'
		;
		grid-gap: 20px;
	}

	.logo {
		grid-area: logo;
		margin-top: 44px;
	}
}

@media (min-width: 1900px) {
	footer {
		background-size: 100% 100%;
	}
}


.footer--block {

	nav {
		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: column;
			list-style: none;
		}

		li {
			margin-bottom: 11px;
		}

		a {
			.fontJost;
			font-weight: 500;
			font-size: 17px;
			text-decoration-line: underline;
		}
	}
}

.footer--header {
	.fontJost;
	font-weight: bold;
	font-size: 24px;
	margin-top: 0;
	margin-bottom: 24px;
	color: white;
	text-decoration: none;

	a {
		color: white;
		text-decoration: none;
	}
}

.footer--business {
	nav ul {
		flex-wrap: wrap;
    max-height: 390px;
	}
}

.footer--email-us {
	grid-area: emailus;
	align-self: end;
	margin-bottom: 22px;
}

.footer--business {
	grid-area: business;
}

.footer--menu {
	grid-area: menu;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	grid-template-areas: 
	'finance interesting'
	'marketing career'
	;
}

.footer--finance {
	grid-area: finance;
}

.footer--marketing {
	grid-area: marketing;
}

.footer--interesting {
	grid-area: interesting;
}

.footer--career {
	grid-area: career;
}

.footer--hr,
.footer--hr2 {
	border-top: 1px solid white;
}

.footer--hr {
	grid-area: hr;
}

.footer--hr2 {
	grid-area: hr2;
	display: none;
}

.footer--copyprivacy {
	grid-area: copyprivacy;
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 20px;
	overflow: hidden;
}

.footer--copy,
.footer--privacy {
	p:first-child {
		margin-top: 5px;
	}
	p:last-child {
		margin-bottom: 40px;
	}
}

.footer--privacy {
	// grid-area: privacy;
	text-align: right;
}


@media (max-width: 1330px) {
	.container-width {
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: border-box;
	}

	.main-nav > ul > li > a,
	.main-nav > ul > li > span {
		padding: 14px 9px;
	}

	h1, .h1 {
		font-size: 74px;
    line-height: 89px;
	}

	.questions--email {
		font-size: 70px;
    line-height: 40px;
	}

	.header-search-opened .header-search form {
    width: 430px;
	}
}

@media (max-width: 1220px) {
	.header-sorting--option {
		padding-left: 10px;
    padding-right: 10px;
	}

	.header-feedback {
		margin-left: 10px;
	}

	.main-nav {
		margin-right: 10px;
	}

	header {
		.logo {
			img {
				width: 180px;
			}
		}
	}

	.main-nav > ul > li > a,
	.main-nav > ul > li > span {
		padding: 14px 5px;
	}

	.main-nav a,
	.main-nav span,
	.header-sorting,
	.header-feedback {
		font-size: 17px;
	}

	header .flex {
		height: 68px;
	}
}

@media (max-width: 1110px) {

	h1, .h1 {
		font-size: 60px;
    line-height: 71px;
	}

	header {
		.logo {
			img {
				width: 150px;
			}
		}
	}

	.main-nav a,
	.main-nav span,
	.header-sorting,
	.header-feedback {
		font-size: 16px;
	}

	header .flex {
		height: 58px;
	}

	footer {
		.container-width {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-areas:
			'logo logo emailus emailus'
			'hr hr hr hr'
			'business business menu menu'
			'hr2 hr2 hr2 hr2'
			'copyprivacy copyprivacy copyprivacy copyprivacy'
			;
			padding-top: 22px;
		}

		.logo {
			margin-top: 0;
		}
	}

	.footer--hr2 {
		display: block;
	}

	.footer--email-us {
		text-align: right;
		margin-bottom: 0;
	}
}


.header--mobile,
.menu--mobile {
	display: none;
}

.header--mobile {
	position: fixed;
	top: 0;
	background: white;
	box-shadow: 0 0 5px fadeOut(black, 70%);
	width: 100%;
	padding: 5px 20px;
	box-sizing: border-box;
	align-items: center;
	z-index: 20;
	height: 55px;
}

@media (max-width: 1160px) {
	.questions {
		display: block;

		br {
			display: none;
		}
	}
}

@media (max-width: 1023px) {

	.items {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.item--w2 {
		grid-column-end: span 3;
	}

	.big-icon {
		grid-template-columns: auto 247px;
		justify-content: center;
	}
}

@media (max-width: 959px) {

	.menu--mobile {
		display: block;
	}
    
	.header--mobile {
		display: grid;
		grid-template-columns: 1fr auto auto auto;
		grid-gap: 15px;
	}

	.header-sorting {
		border-color: transparent;
	}

	.header-sorting--option {
		padding: 9px;
	}

	.header-feedback {
		border: 1px solid @colorActive;
		transition-duration: .2s;

		&:hover {
			margin-bottom: 0;
			// padding-bottom: 0;
			padding: 10px;
			background-color: transparent;
		}
	}

	header {
		height: 53px;
		opacity: 0;

		.container-width {
			display: none;
		}
	}

	h1, .h1 {
		font-size: 6vw;
		line-height: 7vw;
	}
}

@media (max-width: 767px) {
	.items {
		grid-template-columns: 1fr 1fr;
	}

	.item--w2 {
    grid-column-end: span 2;
	}

	.item--big {
    grid-column-end: span 2;
	}

	footer {
		.container-width {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
			'logo emailus emailus'
			'hr hr hr'
			'business menu menu'
			'hr2 hr2 hr2'
			'copyprivacy copyprivacy copyprivacy';
		}
	}

	.footer--business nav ul {
		max-height: none;
	}

	.footer--menu {
		align-self: start;
	}

	.footer--copyprivacy {
		grid-template-columns: auto;
		grid-gap: 0;
	}

	.footer--copy p:last-child, .footer--privacy p:last-child {
    margin-bottom: 20px;
	}

	.footer--privacy {
		text-align: left;
	}

	.questions--email {
		font-size: 8.6vw;
	}
}


@media (max-width: 649px) {
	.header--mobile {
		grid-template-columns: 1fr auto auto;
		grid-gap: 10px;
	}

	.front--about {
		margin-right: 10px;
	}

	.purple-block {
		padding: 5px 18px 15px;
		box-shadow: 10px 10px 0 black;
	}

	.footer--menu {
		grid-template-columns: 1fr;

		grid-template-areas:
		'finance'
		'interesting'
		'marketing'
		'career';
	}

	footer {
		.container-width {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
        'logo emailus'
        'hr hr'
        'business menu'
        'hr2 hr2'
        'copyprivacy copyprivacy';
		}
	}

	.items {
		grid-gap: 61px 20px;
	}
}


@media (max-width: 620px) {
	.big-icon {
		display: block;
		position: relative;
	}
	
	.big-icon--icon {
		position: absolute;
		width: 100px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		img {
			max-width: 100%;
		}
	}

	.big-icon--content {
		margin-top: 50px;
		width: fit-content;
    margin-left: auto;
    margin-right: auto;
	}

	.big-icon--success img {
		top: -14px;
		left: 0;
	}
}


@media (max-width: 580px) {
	h1, .h1 {
		font-size: 33px;
		line-height: 41px;
	}

	.container-width {
		padding-left: 10px;
		padding-right: 10px;
	}

	.questions {
		font-size: 4vw;
    line-height: 5vw;
	}
}


@media (max-width: 530px) {
	footer {
		.container-width {
			grid-template-columns: 1fr;
			grid-template-areas:
        'logo'
				'emailus'
        'hr'
        'business'
				'menu'
        'hr2'
        'copyprivacy';
			justify-items: center;
		}
	}

	.footer--hr, .footer--hr2 {
		width: 100%;
	}

	.footer--email-us {
		text-align: center;
	}

	.footer--business,
	.footer--menu {
		width: 300px;
		max-width: 100%;
	}
}


@media (max-width: 500px) {
	.items {
		grid-template-columns: 1fr;
		justify-items: center;
	}

	.item {
		width: 302px;
	}

	.item--w2,
	.item--big {
    grid-column-end: span 1;
		width: 100%;
	}

	.questions {
		padding: 10px;
	}
}


@media (max-width: 400px) {
	.header--mobile {
		padding: 5px 10px;
	}
}

.menu--mobile {
	padding-top: 100px;
	box-sizing: border-box;
	margin-left: auto;
	background: lighten(@colorMain, 5%);
	padding-bottom: 100px;

	.main-nav,
	.main-nav:hover {
		margin-right: 0;

		ul {
			flex-direction: column;
			width: 100%;

			.active {
				ul {
					display: block !important;
				}
			}

			ul {
				// display: block !important;
				position: static;
				margin: 0;
				background: none;

				padding-left: 30px;
				padding-right: 10px;

				a, span {
					padding: 10px;
					display: block;
					background-image: linear-gradient(to right, #fff 75%, #0000 75%);
					background-position: 0 2em;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		>ul>li {
			display: block;

			>a, >span {
				margin-bottom: 0;
				padding-bottom: 14px;
				padding-left: 20px;
				padding-right: 20px;
			}

			&:hover,
			&.active {
				>a, >span {
					margin-bottom: 0;
					padding-bottom: 14px;
				}
			}
		}
	}

	.header-feedback {
		margin-left: 10px;
    width: ~"calc(100% - 20px)";
    box-sizing: border-box;
    margin-top: 30px;
	}
}

.main-wrap {
	background: white;
}


body {
	.header--mobile {
		color: white;
		background-color: @colorMain;
	}

	.hamburger {
		padding: 6px 10px 0px;
	}

	.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
		height: 1px;
		background-color: white;
	}

	.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
		background-color: white;
	}
}


.breadcrumbs {
	font-size: 17px;
	line-height: 22px;
	margin-top: 31px;
	margin-bottom: 18px;

	a,
	.breadcrumbs-link {
		cursor: pointer;
		text-decoration: underline;
	}
}

h2, .h2 {
	font-size: 40px;
	line-height: 40px;
	.fontJost;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 20px;
	color: black;
}

h3, .h3 {
	font-size: 24px;
	line-height: 30px;
	.fontJost;
	font-weight: bold;
	color: black;
}

.chapter--tags {
	margin-top: 31px;
	margin-bottom: 41px;
}


.chapter-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-bottom: 90px;

	.purple-block {
		padding: 20px;
		padding-bottom: 46px;

		&:not(:first-child) {
			margin-top: 74px;
		}

		h2, .h2 {
			margin-top: 16px;
			margin-bottom: 59px;
		}

		h3, .h3 {
			color: white;
			text-decoration: none;
			margin-top: 40px;
			margin-bottom: 0;

			a {
				color: white;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		a.h3 {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.articles-item {
	// margin-bottom: 30px;

	p {
		margin: 6px 0;
		line-height: 22px;
	}

	.item--stat {
		position: static;
		display: inline-flex;
		background: none;
		padding: 5px 4px;
		margin-top: 3px;
	}

	.item--comments:before {
    background-image: data-uri('../img/comment-white.svg');
	}

	.item--views:before {
    background-image: data-uri('../img/eye-white.svg');
	}
}

.chapter-grid--left {
	grid-column-end: span 3;
}

.chapter-grid--right {
	margin-right: 20px;
}


.pagination {
	display: grid;
	align-items: baseline;
	justify-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas:
	'prev pages next';
	grid-gap: 30px;
	max-width: 955px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 70px;
	.fontJost;
	font-weight: bold;
	font-size: 24px;

	a {
		display: inline-block;
		padding: 2px 10px;
		text-decoration: none;
	}
}

.pagination--prev {
	grid-area: prev;
}

.pagination--next {
	grid-area: next;
}

.pagination--pages {
	grid-area: pages;
}

.pagination--active {
	background: @colorMain;
	color: white;

	&:hover {
		color: white;
	}
}


.chapter--more-header {
	h2, .h2 {
		margin-bottom: 40px;
	}
}


@media (max-width: 779px) {
	.pagination {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
		'prev next'
		'pages pages'
		;
	}

	.pagination--prev {
		justify-self: end;
	}

	.pagination--next {
		justify-self: start;
	}
}


@media (max-width: 500px) {
	.pagination {
		grid-template-columns: 1fr;
		grid-template-areas:
		'next'
		'pages'
		'prev'
		;
	}

	.pagination--prev {
		justify-self: start;
	}

	.pagination--next {
		justify-self: end;
	}
}


@media (max-width: 1200px) and (min-width: 795px) {

	.chapter-grid {
		.pagination {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
			'prev next'
			'pages pages'
			;
		}

		.pagination--prev {
			justify-self: end;
		}

		.pagination--next {
			justify-self: start;
		}
	}
}



@media (max-width: 1023px) {
	.chapter-grid {
		
		.items-3 {
			grid-template-columns: 1fr 1fr;
		}

		.item--big {
			grid-column-end: span 2;
		}
	}
}


@media (max-width: 794px) {
	.chapter-grid {
		
		.items-3 {
			grid-template-columns: 1fr;
		}

		.item--big {
			grid-column-end: span 1;
		}
	}
}

@media (max-width: 794px) and (min-width: 650px) {
	.chapter-grid {

		.pagination {
			grid-template-columns: 1fr;
			grid-template-areas:
			'next'
			'pages'
			'prev'
			;
		}

		.pagination--prev {
			justify-self: start;
		}

		.pagination--next {
			justify-self: end;
		}
	}
}


@media (max-width: 649px) {
	.chapter-grid--right {
		margin-right: 0px;
	}
}


@media (max-width: 649px) {
	.chapter-grid--right {
		margin-right: 0px;
	}

	.item--img {
		margin-left: auto;
    margin-right: auto;
	}

	.chapter-grid--left {
		grid-column-end: span 1;
	}

	.chapter-grid {
		grid-template-columns: 1fr;
	}
}


.content {
	img {
		max-width: 100%;
	}
}


.content--social {
	.ya-share2__icon {
		filter: invert(40%) sepia(14%) saturate(2221%) hue-rotate(241deg) brightness(86%) contrast(86%);
	}
}

.content {
	ol {
		list-style: none;
		counter-reset: myCounter;
		margin-top: 63px;
		padding: 0;
		padding-right: 50px;

		li {
			padding-left: 59px;
			position: relative;
			margin-top: 30px;

			&:before {
				counter-increment: myCounter;
				content:counter(myCounter);
				color: white;
				background: @colorActive;
				display: flex;
				width: 37px;
				height: 37px;
				justify-content: center;
				align-items: center;
				position: absolute;
				left: 0;
			}
		}
	}
}



.article {
	position: relative;
	font-size: 22px;

	.breadcrumbs {
		position: absolute;
		padding: 15px 13px;
		background: white;
		top: -6px;
		left: 0;
	}

	p, li {
		line-height: 35px;
	}

	h2 {
		margin-top: 44px;
	}
}


.article--poster {
	margin-top: -7px;
	margin-bottom: 14px;
}


.article--info {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 7px;
	width: fit-content;
	width: -moz-fit-content;
	font-size: 12px;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 32px;

	.item--stat {
		position: static;
		padding-left: 0;
	}
}

.article--bottom-info {
	@margin: 47px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-top: 37px-@margin;
	margin-left: -@margin;
	margin-bottom: 97px;

	>* {
		margin-top: @margin;
		margin-left: @margin;
	}
}

.article--label {
	font-size: 12px;
	margin-bottom: 4px;
}

.article--author {
	color: @colorMain;
	.fontJost;
	font-weight: 700;
	font-size: 24px;
}


body {
	.ya-share2__container_shape_round.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
		background-size: 34px 34px;
	}

	.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
		height: 34px;
		width: 29px;
		background-size: 34px 34px;
	}
}


.article__feedback-form {
	margin-top: 45px;
	margin-bottom: 100px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
	'name email'
	'text text'
	'privacy privacy'
	'submit submit'
	;
	grid-gap: 43px 30px;
	max-width: 850px;

	input[type="text"],
	textarea {
		width: 100%;
		box-sizing: border-box;
	}

	textarea {
		height: 217px;
	}
}

.article__feedback-form__name {
	grid-area: name;
}

.article__feedback-form__email {
	grid-area: email;

	.input-comment {
		margin-top: 5px;
	}
}

.article__feedback-form__text {
	grid-area: text;
}

.article__feedback-form__privacy {
	grid-area: privacy;
	margin-top: -9px;

	
}

.form-privacy {
	.input-comment {
		margin-top: 8px;
	}

	a {
		text-decoration: none;
		color: @colorMain;

		&:hover {
			text-decoration: underline;
		}
	}
}

.article__feedback-form__submit {
	grid-area: submit;
}


input[type="text"],
textarea {
	border: 5px solid black;
	background: white;
	padding: 17px;
	.fontJost;
	font-weight: 400;
	font-size: 21px;
	color: black;

	&::placeholder {
		.fontJost;
		font-weight: 400;
		font-size: 21px;
		font-style: italic;
		color: black;
	}
}

input[type="checkbox"]:checked, 
input[type="checkbox"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

input[type="checkbox"]:checked + label, 
input[type="checkbox"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 48px;
    line-height: 20px;
    cursor: pointer;
}

input[type="checkbox"]:checked + label:before, 
input[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: -8px;
    width: 34px;
    height: 34px;
    border: 5px solid black;
    background-color: white;
		box-sizing: border-box;
}

input[type="checkbox"]:checked + label:after, 
input[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    // transition: all 0.1s ease;
}

input[type="checkbox"]:checked + label:after, 
input[type="checkbox"]:not(:checked) + label:after {
    left: 8px;
    top: 1px;
    width: 13px;
    height: 6px;
    border-radius: 0px;
    border-left: 5px solid black;
    border-bottom: 5px solid black;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
}

input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.input-comment {
	.fontJost;
	font-weight: 300;
	font-size: 21px;
}

form {
	button[type="submit"] {
		min-width: 248px;
	}
}


@media (max-width: 690px) {
	.article__feedback-form {
		grid-template-columns: 1fr;
		grid-template-areas:
			'name'
			'email'
			'text'
			'privacy'
			'submit';
	}

	.article {
		font-size: 19px;

		p, li {
			line-height: 32px;
		}
	}
}


.contacts {
	font-size: 22px;

	p {
		line-height: 35px;
	}
}


.contacts--block {
	margin-top: 70px;
	margin-bottom: 94px;

	strong {
		font-size: 24px;
		.fontJost;
		font-weight: bold;
	}
}


.contacts--email {
	font-weight: 900;
	font-size: 131px;
	.fontJost;
	margin-bottom: 23px;
	
	a {
		background: @colorActive;
		color: black;
		text-decoration: none;
		white-space: pre;
	}
}


@media (max-width: 1320px) {
	.contacts--email {
		font-size: 9.6vw;
	}
}

@media (max-width: 750px) {
	.contacts--email {
		font-size: 9vw;
	}
}