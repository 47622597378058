@keyframes loadingAnimation {
	from
	{
		background-position-x: 0;
	}
	to
	{
		background-position-x: 80px;
	}
}
@-webkit-keyframes loadingAnimation {
	from
	{
		background-position-x: 0;
	}
	to
	{
		background-position-x: 80px;
	}
}


.loading
{
	background-color: #AAAAAA;
	border: 1px solid #AAAAAA;
	position: absolute;
	z-index: 1;
}

.loading, .loadingWhite
{
	animation-name: loadingAnimation;
	animation-duration: 4s;
	animation-iteration-count: infinite;
	animation-timing-function: steps(16);

	background-image: data-uri("./loading.png");
	box-sizing: border-box;
}